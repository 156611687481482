import { ReactNode, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { useIsHomebridge } from "utils/useIsHomebridge";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { privateLabelTheme } from "./privateLabelTheme";
import { PrivateLabel } from "typedef/PrivateLabel";
import { homebridgeTheme } from "./homebridgeTheme";
import { useLocation } from "react-router-dom";

export const MainThemeUI: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { privateLabel } = usePrivateLabel();
  const { isHomeBridge } = useIsHomebridge();
  const {
    primaryText,
    primaryBackground,
    secondaryBackground,
    secondaryText,
    tertiaryText,
    fourthText,
  } = useHomeBridgeColors();
  const { pathname } = useLocation();
  const isMyLoansBorrowerTrackerOrFinalOffer =
    pathname.includes("/loans") ||
    pathname.includes("/impersonate") ||
    pathname.includes("/borrower-tracker") ||
    pathname.includes("/coborrower-tracker") ||
    pathname.includes("/final-offer");

  const homebridgeThemeObject = homebridgeTheme({
    primaryText,
    primaryBackground,
    secondaryText,
    secondaryBackground,
    tertiaryText,
    fourthText,
    privateLabel,
  });
  const privateLabelThemeObject = privateLabelTheme(
    privateLabel as PrivateLabel,
  );

  const theme = createTheme(
    isHomeBridge && !isMyLoansBorrowerTrackerOrFinalOffer
      ? homebridgeThemeObject
      : privateLabelThemeObject,
  );
  useEffect(() => {
    const root = document.documentElement;

    root.style.setProperty(
      "--palette-text-primary",
      `${
        isHomeBridge
          ? isMyLoansBorrowerTrackerOrFinalOffer
            ? secondaryText
            : primaryText
          : privateLabel?.pallete?.text?.secondary
      }`,
    );
    root.style.setProperty(
      "--palette-background-paper",
      `${privateLabel?.pallete?.background?.paper}`,
    );
    root.style.setProperty(
      "--palette-text-secondary",
      `${
        isHomeBridge
          ? isMyLoansBorrowerTrackerOrFinalOffer
            ? secondaryText
            : secondaryText
          : privateLabel?.pallete?.text?.secondary
      }`,
    );
    // root.style.setProperty(
    //   "--palette-background-default",
    //   `${
    //     isHomeBridge
    //       ? primaryBackground
    //       : privateLabel?.pallete?.background?.default
    //   }`,
    // );
  }, [
    privateLabel?.pallete,
    isHomeBridge,
    primaryText,
    secondaryText,
    primaryBackground,
    isMyLoansBorrowerTrackerOrFinalOffer,
  ]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
