import { Divider, Grid, Typography, Box, useTheme } from "@mui/material";
import { DebtConsolidation } from "components/icons/DebtConsolidation";
import { HomeImprovement } from "components/icons/HomeImprovement";
import { SecondHome } from "components/icons/SecondHome";
import { Other } from "components/icons/Other";
import { CarOrTruckPurchase } from "components/icons/CarOrTruckPurchase";
import HBIconAndText from "components/HBIconAndText";
import { TuitionAndStudentLoans } from "components/icons/TutionAndStudentLoans";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";

interface HBUseYourHelocForProps {
  isMobile: boolean;
}

export default function HBUseYourHelocFor({
  isMobile,
}: HBUseYourHelocForProps) {
  const theme = useTheme();
  const { sizes } = useHomebridgeSizes();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{
        backgroundColor: theme.palette.background.paper,
        padding: "40px 20px",
        borderRadius: "8px",
        color: theme.palette.common.white,
      }}
    >
      <Divider
        orientation="horizontal"
        sx={{
          border: `1px solid ${theme.palette.text.primary}`,
          width: { xs: "85%", md: "50%", xl: "50%" },
          mb: 5,
        }}
      />
      <Typography
        variant="body1"
        color={theme.palette.common.white}
        fontSize={sizes.typography}
        letterSpacing={1}
        textAlign="center"
        fontWeight={300}
        marginTop={5}
        sx={{
          marginBottom: "40px",
          width: { xs: "350px", sm: "400px", md: "500px", lg: "700px" },
        }}
      >
        If your home is worth more than what you owe on your mortgage, you have
        home equity. And you can turn that equity into cash <i>fast</i> with a
        Homebridge HELOC (home equity line of credit)!
      </Typography>
      <Typography
        variant="h6"
        color={theme.palette.common.white}
        textAlign="center"
        sx={{
          fontSize: sizes.subtitle,
          marginBottom: "45px",
        }}
        fontWeight={900}
        fontFamily={"NunitoSans"}
      >
        Use your HELOC for:
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{
          maxWidth: "880px",
          width: { xs: "100%", sm: "80%", md: "70%", lg: "60%" },
        }}
      >
        <Grid
          item
          xs={6}
          sm={4}
          md={3.5}
          marginBottom="30px"
          display="flex"
          justifyContent="center"
        >
          <HBIconAndText
            IconComponent={HomeImprovement}
            text="Home Improvements"
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={3.5}
          marginBottom="30px"
          display="flex"
          justifyContent="center"
        >
          <HBIconAndText IconComponent={SecondHome} text="Vacation Home" />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={3.5}
          marginBottom="30px"
          display="flex"
          justifyContent="center"
        >
          <HBIconAndText
            IconComponent={DebtConsolidation}
            text="Debt Consolidation"
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={3.5}
          width={isMobile ? "100%" : "70%"}
          marginBottom="30px"
          display="flex"
          justifyContent="center"
        >
          <HBIconAndText
            IconComponent={CarOrTruckPurchase}
            text="Car or Truck Purchase"
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={3.5}
          marginBottom="30px"
          display="flex"
          justifyContent="center"
        >
          <HBIconAndText
            IconComponent={TuitionAndStudentLoans}
            text="Tuition and Student Loans"
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={3.5}
          marginBottom="30px"
          display="flex"
          justifyContent="center"
        >
          <HBIconAndText IconComponent={Other} text="Big-Ticket Items" />
        </Grid>
      </Grid>
    </Box>
  );
}
