import { useEffect, useState } from "react";
import CAvatar from "components/CAvatar";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CCustomLogo from "components/CCustomLogo";
import { Turn as Hamburger } from "hamburger-react";
import useInvite from "context/Invite/useInviteContext";
import CLoginButton from "./CButtonsHeader/CLoginButton";
import useTracker from "components/CTracker/useTrackerContext";
import {
  Box,
  Drawer,
  Toolbar,
  Container,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Avatar,
  Typography,
} from "@mui/material";
import CContactusButton from "./CButtonsHeader/CContactusButton";
import useUser from "context/UserCustomer/useUserCustomerContext";
import CForlendersButton from "./CButtonsHeader/CForlendersButton";
import CContactusGladlyButton from "./CButtonsHeader/CContactusGladlyButton";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import FortunaLogo from "assets/Images/Fortuna-logo.png";
import LaduLogo from "assets/Images/Ladu-logo.png";
import CButton from "components/CButton";
import CBadge from "components/CAvatar/CBadge";
import { useIsHomebridge } from "utils/useIsHomebridge";

const CHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const isScreenWiderThanMD = useMediaQuery((t: any) => t.breakpoints.up("md"));
  const {
    user,
    userAdmin,
    getAdminLoan,
    loadingLoan,
    loan: adminLoan,
    openedDrawer,
    setOpenedDrawer,
  } = useUser();
  const invite = useInvite();
  const currentLocation = useLocation();
  const isAdminPath = currentLocation.pathname.includes("impersonate");
  const params = useParams();
  const [searchParams] = useSearchParams();
  const isAllLoansScreen = searchParams.get("allLoans");
  const { loan } = useTracker();
  const sourceId = params["sourceId"];
  const theme = useTheme();
  const { privateLabel } = usePrivateLabel();

  const isBorrower = params?.borrowerType === "borrower";
  const logo = invite?.broker?.logo
    ? invite?.broker?.logo
    : loan?.broker?.logo
      ? loan?.broker?.logo
      : sourceId === "fortuna"
        ? "fortuna"
        : sourceId === "ladu"
          ? "ladu"
          : privateLabel
            ? privateLabel.mediaResources.logo
            : "NFTYDoor";

  const redirectLogedInUser = user ? "/loans" : "/";

  const brokerHasLogo = invite?.broker?.logo || loan?.broker?.logo;
  const link =
    brokerHasLogo && invite?.broker?.web
      ? invite?.broker?.web
      : redirectLogedInUser;

  const isLogoClickable = () => {
    if (brokerHasLogo && !!(link === "/")) {
      return false;
    }
    return true;
  };

  const pathname = window.location.pathname;

  const { isHomeBridge } = useIsHomebridge();

  const isLongHeader =
    pathname === "/" ||
    pathname === "/faqs" ||
    pathname === "/lenders" ||
    pathname === "*";

  const isLender = pathname === "/lenders";
  const isLogin = pathname === "/login";

  const isSignupForm =
    pathname.slice(pathname.length - 6, pathname.length) === "signup";

  const isTracker =
    pathname === "/borrower-tracker" || pathname === "/coborrower-tracker";

  const wasSubmitted = pathname === "/submitted";
  const [countDown, setCountDown] = useState(5);

  useEffect(() => {
    if (userAdmin?.email && userAdmin?.id) {
      const interval = setInterval(() => {
        if (countDown === 0) {
          getAdminLoan();
          setCountDown(5);
        } else {
          setCountDown(countDown - 1);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [userAdmin, getAdminLoan, countDown]);

  return (
    <Box
      top={0}
      zIndex={100}
      position="sticky"
      display={"flex"}
      justifyContent={"space-between"}
      borderBottom={isHomeBridge ? "none" : "1px solid #31313127"}
      bgcolor={
        isLongHeader
          ? theme.palette.background.paper
          : isHomeBridge
            ? privateLabel?.pallete.background?.paper
            : theme.palette.background.default
      }
    >
      <Container
        maxWidth={isLongHeader ? "lg" : "md"}
        sx={{
          height: "90px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Toolbar disableGutters>
          <Box
            flexGrow={1}
            display={{ xs: "flex", md: "none" }}
            {...(isScreenWiderThanMD && {
              "aria-hidden": "true",
              "data-acsb-hidden": "true",
            })}
          >
            <Box>
              {logo === "fortuna" ? (
                <CCustomLogo
                  handleClick={() => setOpenedDrawer(false)}
                  link={"/"}
                  logo={FortunaLogo}
                />
              ) : logo === "ladu" ? (
                <CCustomLogo
                  handleClick={() => setOpenedDrawer(false)}
                  link={"/"}
                  logo={LaduLogo}
                />
              ) : (
                <CCustomLogo
                  handleClick={() => setOpenedDrawer(false)}
                  link={link}
                  logo={logo}
                  isLogoClickable={isLogoClickable()}
                />
              )}
            </Box>

            <Drawer
              open={openedDrawer}
              anchor="left"
              variant="persistent"
              sx={{
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  top: "90px",
                  width: "100%",
                  boxSizing: "border-box",
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              <Box
                px={3}
                pt={3}
                display="flex"
                alignItems={{ md: "center", xs: "start" }}
                flexDirection={{ xs: "column", md: "row" }}
              >
                {isAdminPath && userAdmin && adminLoan?.id && (
                  <>
                    {adminLoan?.coborrowerId &&
                      !adminLoan?.coborrowerId?.includes("temp_coborrower") && (
                        <CButton
                          type="button"
                          variant={"contained"}
                          onClick={() => {
                            navigate(
                              isBorrower
                                ? `/impersonate/coborrower/${params.loanId}${location.search}`
                                : `/impersonate/borrower/${params.loanId}${location.search}`,
                            );
                            setOpenedDrawer(false);
                          }}
                          sx={{
                            marginRight: "26px",
                            marginBottom: "26px",
                          }}
                        >
                          {isBorrower ? "View Co-Borrower" : "View Borrower"}
                        </CButton>
                      )}

                    <Typography
                      variant="body1"
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: "1.5rem",
                      }}
                    >
                      Refresh in{" "}
                      {loadingLoan || countDown === 6 || countDown === 0
                        ? null
                        : countDown + 1}
                    </Typography>
                  </>
                )}
                {!isTracker ? (
                  !(isSignupForm && invite) &&
                  !isAdminPath && (
                    <CContactusButton
                      handleClick={() => setOpenedDrawer(false)}
                      pathname={pathname}
                      contactUsLink={privateLabel?.contactUsLink}
                    />
                  )
                ) : (
                  <CContactusGladlyButton />
                )}

                {((!isAdminPath &&
                  !isLogin &&
                  !user &&
                  !isSignupForm &&
                  !isLender &&
                  !wasSubmitted) ||
                  (isSignupForm && !invite)) && (
                  <CLoginButton handleClick={() => setOpenedDrawer(false)} />
                )}
                {privateLabel?.isNFTYDoor &&
                  !isLogin &&
                  !user &&
                  !isLender &&
                  !isSignupForm &&
                  !wasSubmitted && (
                    <CForlendersButton
                      handleClick={() => setOpenedDrawer(false)}
                      isLongHeader={isLongHeader}
                    />
                  )}
                <Box
                  sx={{ display: { xs: "none", md: "flex" } }}
                  {...(!user?.id && {
                    "aria-hidden": "true",
                    "data-acsb-hidden": "true",
                  })}
                >
                  <CAvatar />
                </Box>
              </Box>
            </Drawer>
          </Box>
        </Toolbar>

        <Box
          alignItems={"center"}
          display={{ xs: "flex", md: "none" }}
          data-acsb-clickable={true}
          data-acsb-navigable={true}
          data-acsb-now-navigable={false}
          sx={{ WebkitTapHighlightColor: "transparent" }}
          {...(isScreenWiderThanMD && {
            "aria-hidden": "true",
            "data-acsb-hidden": "true",
          })}
        >
          <Box
            mr={4}
            {...(!user?.id && {
              "aria-hidden": "true",
              "data-acsb-hidden": "true",
            })}
          >
            <CAvatar />
          </Box>
          <Box m={-1}>
            <Hamburger
              size={30}
              toggled={openedDrawer}
              toggle={setOpenedDrawer}
              color={theme.palette.primary.main}
            />
          </Box>
        </Box>

        <Box
          flexGrow={1}
          alignItems={"center"}
          justifyContent={"space-between"}
          display={{ xs: "none", md: "flex" }}
          {...(!isScreenWiderThanMD && {
            "aria-hidden": "true",
            "data-acsb-hidden": "true",
          })}
        >
          {logo === "fortuna" ? (
            <CCustomLogo
              handleClick={() => setOpenedDrawer(false)}
              link={"/"}
              logo={FortunaLogo}
            />
          ) : logo === "ladu" ? (
            <CCustomLogo
              handleClick={() => setOpenedDrawer(false)}
              link={"/"}
              logo={LaduLogo}
            />
          ) : (
            <CCustomLogo
              handleClick={() => setOpenedDrawer(false)}
              link={link}
              logo={logo}
              isLogoClickable={isLogoClickable()}
            />
          )}
          <Box
            display="flex"
            alignItems={{ md: "center", xs: "start" }}
            flexDirection={{ xs: "column", md: "row" }}
            gap={0}
          >
            {isAdminPath && userAdmin && adminLoan?.id && (
              <>
                {adminLoan?.coborrowerId &&
                  !adminLoan?.coborrowerId?.includes("temp_coborrower") && (
                    <CButton
                      type="button"
                      variant={"contained"}
                      onClick={() =>
                        navigate(
                          isBorrower
                            ? `/impersonate/coborrower/${params.loanId}${location.search}`
                            : `/impersonate/borrower/${params.loanId}${location.search}`,
                        )
                      }
                    >
                      {isBorrower ? "View Co-Borrower" : "View Borrower"}
                    </CButton>
                  )}

                {!isAllLoansScreen && (
                  <CButton
                    style={{ marginLeft: "5px", marginRight: "5px" }}
                    loading={false}
                    type="button"
                    variant={"contained"}
                    onClick={() =>
                      navigate(
                        isBorrower
                          ? `/impersonate/borrower/${params.loanId}${
                              location.search.length > 0
                                ? "&allLoans=true"
                                : "?allLoans=true"
                            }`
                          : `/impersonate/coborrower/${params.loanId}${
                              location.search.length > 0
                                ? "&allLoans=true"
                                : "?allLoans=true"
                            }`,
                      )
                    }
                  >
                    Go to your loans
                  </CButton>
                )}

                <Typography
                  variant="body1"
                  sx={{
                    width: "120px",
                    color: theme.palette.primary.main,
                    fontSize: "1.5rem",
                  }}
                >
                  Refresh in{" "}
                  {loadingLoan || countDown + 1 === 6 || countDown + 1 === 0
                    ? null
                    : countDown + 1}
                </Typography>

                <CBadge
                  variant="dot"
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  sx={{
                    marginLeft: "26px",
                  }}
                >
                  {!userAdmin && <CircularProgress color="primary" />}
                  {userAdmin && (
                    <Avatar
                      sx={{
                        width: { xs: 45, md: 60 },
                        height: { xs: 45, md: 60 },
                        bgcolor: theme.palette.primary.main,
                        textTransform: "uppercase",
                      }}
                    >
                      {userAdmin?.firstName?.[0]} {userAdmin?.lastName?.[0]}
                    </Avatar>
                  )}
                </CBadge>
              </>
            )}
            {!isTracker ? (
              !(isSignupForm && invite) &&
              !isAdminPath && (
                <CContactusButton
                  pathname={pathname}
                  contactUsLink={privateLabel?.contactUsLink}
                />
              )
            ) : (
              <CContactusGladlyButton />
            )}

            {((!isAdminPath &&
              !isLogin &&
              !user &&
              !isSignupForm &&
              !isLender &&
              !wasSubmitted) ||
              (isSignupForm && !user && !invite)) && (
              <CLoginButton handleClick={() => setOpenedDrawer(false)} />
            )}
            {privateLabel?.isNFTYDoor &&
              !isAdminPath &&
              !isLogin &&
              !user &&
              !isLender &&
              !isSignupForm &&
              !wasSubmitted && (
                <CForlendersButton
                  handleClick={() => setOpenedDrawer(false)}
                  isLongHeader={isLongHeader}
                />
              )}
            <Box
              sx={{ display: { xs: "none", md: "flex" } }}
              {...(!user?.id && {
                "aria-hidden": "true",
                "data-acsb-hidden": "true",
              })}
            >
              <CAvatar />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CHeader;
