import moment from "moment";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  useTheme,
  Container,
  useMediaQuery,
} from "@mui/material";
import useUserCustomerContext from "context/UserCustomer/useUserCustomerContext";

export default function DtcInputs() {
  const { loan } = useUserCustomerContext();
  const theme = useTheme();

  const formatDate = (dateString: string | Date | undefined) => {
    return dateString ? moment(dateString).format("MM/DD/YYYY") : "-";
  };

  const formatValue = (
    value: string | number | undefined,
    isPercentage = false,
    multiplier = 1,
  ) => {
    if (typeof value !== "number" || isNaN(value)) return "-";
    return isPercentage
      ? `${(value * multiplier).toFixed(2)}%`
      : `$${value.toFixed(2)}`;
  };

  const renderRow = (
    label: string | number | undefined,
    value: string | number | undefined,
  ) => (
    <Box display="flex" justifyContent="space-between" mb={1}>
      <Typography variant="body2" fontWeight="bold">
        {label}:
      </Typography>
      <Typography variant="body2" color={theme.palette.primary.main}>
        {value}
      </Typography>
    </Box>
  );

  return (
    <Container
      className="loans__container"
      maxWidth="md"
      disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
    >
      <Box
        mt={1}
        bgcolor={theme.palette.background.paper}
        px={{ xs: 2, md: 6 }}
        py={{ xs: 4, md: 5 }}
        sx={{ borderRadius: 33 }}
      >
        <Typography variant="h5" fontFamily={"Pro Text Semibold"}>
          DTC Inputs
        </Typography>
        <Typography variant="body1" gutterBottom>
          DTC Date: {formatDate(loan?.apDate)}
        </Typography>
        <Box>
          <Card variant="outlined" sx={{ mb: 2, borderRadius: 10 }}>
            <CardHeader
              title="DTC Inpunt"
              titleTypographyProps={{ variant: "h6", color: "white" }}
              bgcolor="white"
            />
            <CardContent>
              {renderRow(
                "Home Value",
                formatValue(loan?.DTCApplication?.homeValue),
              )}
              {renderRow("FICO", loan?.DTCApplication?.creditScore ?? "-")}
              {renderRow(
                "Loan Balance",
                formatValue(loan?.DTCApplication?.loanBalance),
              )}
              {renderRow(
                "Loan Amount",
                formatValue(loan?.DTCApplication?.requestedLoanAmount),
              )}
              {renderRow(
                "Monthly Debt",
                formatValue(loan?.initialOffer?.monthlyPayment),
              )}
              {renderRow(
                "NFTYDoor Payment",
                formatValue(loan?.initialOffer?.monthlyPaymentInitialDraw),
              )}
              {renderRow(
                "Annual Income",
                formatValue(loan?.DTCApplication?.annualIncome),
              )}
            </CardContent>
          </Card>
          <Card variant="outlined" sx={{ borderRadius: 10 }}>
            <CardHeader
              title="Offer Calculations"
              titleTypographyProps={{ variant: "h6", color: "white" }}
            />
            <CardContent>
              {renderRow(
                "DTI",
                formatValue(loan?.initialOffer?.dti, true, 0.2),
              )}
              {renderRow(
                "CLTV",
                formatValue(loan?.initialOffer?.cltv, true, 0.2),
              )}
              {renderRow(
                "Interest Rate",
                formatValue(loan?.initialOffer?.marginRate, true, 0.2),
              )}
              {renderRow(
                "Min Offer",
                formatValue(loan?.initialOffer?.minOffer),
              )}
              {renderRow(
                "Max Offer",
                formatValue(loan?.initialOffer?.maxOffer),
              )}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Container>
  );
}
