import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { HELOCFormProps } from "typedef/HELOCFormProps";
import { useIsHomebridge } from "utils/useIsHomebridge";

const Property: FC<HELOCFormProps> = ({ loan }) => {
  const theme = useTheme();
  const property = loan?.property;
  const { isHomeBridge } = useIsHomebridge();

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr"
      alignItems="center"
      sx={{
        backgroundColor: isHomeBridge
          ? "#D3E1EA"
          : theme.palette.background.paper,
        p: 2,
      }}
    >
      <Typography sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }} variant="h5">
        Property:
      </Typography>
      <Box display="flex" alignItems="center">
        {property ? (
          <Typography
            variant="h5"
            color={theme.palette.text.primary}
            fontFamily={"Pro Display Bold"}
            sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
          >
            {property?.address?.delivery_line_1}.{" "}
            {property?.address?.components?.city_name},{" "}
            {property?.address?.components?.state_abbreviation}.{" "}
            {property?.address?.components?.zipcode}
          </Typography>
        ) : (
          <Skeleton width="75%" height={"1.5rem"} />
        )}
      </Box>
    </Box>
  );
};

export default Property;
