import { Box, Typography, useTheme } from "@mui/material";
import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { useNavigate } from "react-router-dom";
import { InfoAccordion } from "screens/HBFaqAndResources/infoAccordion";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";

export const HBFaqAccordion = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { sizes } = useHomebridgeSizes();

  return (
    <Box
      width={{ xs: "98%", md: "80%", lg: "80%", xl: "85%" }}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      height={"auto"}
      gap={"32px"}
      margin={"0 auto"}
    >
      <InfoAccordion />
      <CHBButton
        onClick={() => {
          navigate("/get-started");
        }}
      >
        Get started
      </CHBButton>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={2}
      >
        <Typography
          variant="body1"
          color={theme.palette.background.paper}
          fontSize={sizes.typography}
        >
          Get approved in minutes
        </Typography>
        <HBSeparate />
        <Typography
          variant="body1"
          color={theme.palette.background.paper}
          fontSize={sizes.typography}
        >
          Get cash in days
        </Typography>
      </Box>
    </Box>
  );
};
