import { SimplePaletteColorOptions, ThemeOptions } from "@mui/material";
import { PrivateLabel } from "typedef/PrivateLabel";

export const privateLabelTheme = (privateLabel: PrivateLabel): ThemeOptions => {
  const isHomebridge = privateLabel?.isHomebridge;

  const defaultFontFamily = {
    "Pro Display Regular": isHomebridge ? "NunitoSans" : "Pro Display Regular",
    "Pro Text Semibold": "Pro Text Semibold",
    "Pro Display Bold": "Pro Display Bold",
    "Pro Display Semibold": "Pro Display Semibold",
    "Pro Display Medium": "Pro Display Medium",
  };
  return {
    palette: privateLabel?.pallete,
    typography: {
      fontFamily: [
        '"NunitoSans"',
        '"Titillium Bold"',
        '"Titillium Black"',
        '"Titillium Regular"',
        '"Pro Display Regular"',
        '"Pro Text Semibold"',
        '"Pro Display Bold"',
        '"Pro Display Semibold"',
        '"Pro Display Medium"',
      ].join(","),
      h1: { fontFamily: defaultFontFamily["Pro Display Bold"] },
      h2: { fontFamily: defaultFontFamily["Pro Display Semibold"] },
      h3: { fontFamily: defaultFontFamily["Pro Display Semibold"] },
      h4: { fontFamily: defaultFontFamily["Pro Display Semibold"] },
      h5: {
        fontFamily: defaultFontFamily["Pro Display Regular"],
        color: privateLabel?.pallete?.text?.primary,
      },
      h6: { fontFamily: defaultFontFamily["Pro Display Regular"] },
      subtitle1: { fontFamily: defaultFontFamily["Pro Display Regular"] },
      subtitle2: { fontFamily: defaultFontFamily["Pro Display Regular"] },
      body1: {
        fontFamily: defaultFontFamily["Pro Display Regular"],
      },
      body2: { fontFamily: defaultFontFamily["Pro Display Regular"] },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: privateLabel?.pallete?.text?.secondary,
            borderRadius: "12px",
            background: privateLabel?.pallete?.background?.paper,
            fontFamily: defaultFontFamily["Pro Display Regular"],
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            borderColor: privateLabel?.pallete?.background?.paper,
            backgroundColor: (
              privateLabel?.pallete?.primary as SimplePaletteColorOptions
            ).main,
            borderWidth: "0.01rem !important",
          },
          outlined: {
            borderColor: (
              privateLabel?.pallete?.primary as SimplePaletteColorOptions
            )?.main,
            backgroundColor: privateLabel?.pallete?.background?.paper,
            borderWidth: "0.01rem !important",
            ":hover": {
              backgroundColor: (
                privateLabel?.pallete?.primary as SimplePaletteColorOptions
              )?.light,
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paperWidthXs: {
            maxWidth: 410,
          },
          paperWidthMd: {
            maxWidth: 500,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& legend": {
              width: "0",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          formControl: {
            position: "relative",
          },
          root: {
            transform: "unset !important",
            padding: "0 0.2rem",
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            gap: "4px",
          },
        },
      },
    },
  };
};
