import {
  Box,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Typography,
  Grid,
  Button,
  useMediaQuery,
} from "@mui/material";
import { Theme } from "@mui/material";
import HBCardImage from "../../assets/Images/resource-card-homebridge.png";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const cards = [
  { title: "what-is-home-equity", content: "What is home equity?" },
  {
    title: "how-to-get-a-heloc",
    content: "How to get a HELOC",
  },
  {
    title: "is-a-heloc-a-good-idea",
    content: "Is a HELOC a good idea?",
  },
  {
    title: "what-is-a-heloc",
    content: "What is a HELOC?",
  },
];

export const HBResources = () => {
  const [visibleCards, setVisibleCards] = useState(6);
  const [buttonText, setButtonText] = useState("Load More");
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const cardsRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { sizes } = useHomebridgeSizes();

  useEffect(() => {
    if (isMobile) {
      setVisibleCards(3);
    } else {
      setVisibleCards(cards.length);
    }
  }, [isMobile]);

  const handleLoadMore = () => {
    if (buttonText === "Load More") {
      const newVisibleCards = visibleCards + 3;
      if (newVisibleCards >= cards.length) {
        setVisibleCards(cards.length);
        setButtonText("Load Less");
      } else {
        setVisibleCards(newVisibleCards);
      }
    } else {
      setVisibleCards(3);
      setButtonText("Load More");
      if (cardsRef.current) {
        cardsRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const { primaryText, secondaryText } = useHomeBridgeColors();
  return (
    <Box sx={{ width: { xs: "98%", md: "80%", lg: "80%", xl: "85%" } }}>
      <Grid container spacing={2} ref={cardsRef}>
        {cards.slice(0, visibleCards).map((card, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Card
              sx={{
                backgroundColor: primaryText,
                boxShadow: "none",
                borderRadius: "12px",
                height: "100%",
                maxHeight: "275px",
              }}
              onClick={() => navigate(`/resources/${card.title}`)}
            >
              <CardActionArea
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <CardMedia
                  component="img"
                  image={HBCardImage}
                  alt="Home Image HB"
                  sx={{
                    minHeight: { xs: "120px", md: "60%", xl: "60%" },
                    objectFit: "cover",
                  }}
                />
                <CardContent
                  sx={{
                    height: { xs: "120px", md: "40%" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    alignContent: isMobile ? "center" : "none",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: secondaryText,
                      textAlign: "center",
                      fontSize: sizes.subtitle,
                    }}
                  >
                    {card.content}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      {isMobile && (
        <Box display="flex" justifyContent="center" mt={2}>
          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              variant="contained"
              onClick={handleLoadMore}
              sx={{
                backgroundColor: primaryText,
                color: secondaryText,
                border: `2px solid ${secondaryText}`,
                padding: "12px 23px",
                borderRadius: "12px",
                fontSize: sizes.typography,
              }}
            >
              {buttonText}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
