import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import CButton from "components/CButton";
import { FC, useEffect, useState } from "react";
import { HELOCFormProps } from "typedef/HELOCFormProps";
import ShowNetDetailsModal from "./showNetDetailsModal";
import dollarFormatter from "utils/FormatterDollar";
import { useIsHomebridge } from "utils/useIsHomebridge";

const EstNetProceeds: FC<HELOCFormProps> = ({
  loan,
  totalFees,
  newValuesSlider,
}) => {
  const theme = useTheme();
  const { isHomeBridge } = useIsHomebridge();
  const [showDetails, setShowDetails] = useState(false);
  const [totalPayoffs, setTotalPayoffs] = useState(0);
  const [totalPaydowns, setTotalPaydowns] = useState(0);
  const [paydowns, setPaydowns] = useState<
    {
      subscriberName: string;
      updatedAt: number;
      counterpartyId: string;
      receivingAccountId: string;
      paydownAmount: string;
      accountNumber: string;
    }[]
  >([]);

  useEffect(() => {
    let payoffs = 0;
    let paydowns = 0;
    if (loan?.payoff) {
      loan.payoff.forEach((element) => {
        payoffs += element?.amount ?? 0;
      });
    }
    if (
      loan?.borrowerSteps?.hardpullVerification?.paydownInfo &&
      loan?.coborrowerSteps?.hardpullVerification?.paydownInfo
    ) {
      loan?.borrowerSteps?.hardpullVerification?.paydownInfo?.forEach(
        (element) => {
          paydowns += element.paydownAmount
            ? parseInt(element?.paydownAmount, 10)
            : 0;
        },
      );
      loan?.coborrowerSteps?.hardpullVerification?.paydownInfo?.forEach(
        (element) => {
          paydowns += element.paydownAmount
            ? parseInt(element?.paydownAmount, 10)
            : 0;
        },
      );
    } else if (loan?.borrowerSteps?.hardpullVerification?.paydownInfo) {
      loan?.borrowerSteps?.hardpullVerification?.paydownInfo?.forEach(
        (element) => {
          paydowns += element.paydownAmount
            ? parseInt(element?.paydownAmount, 10)
            : 0;
        },
      );
    } else if (loan?.coborrowerSteps?.hardpullVerification?.paydownInfo) {
      loan?.coborrowerSteps?.hardpullVerification?.paydownInfo?.forEach(
        (element) => {
          paydowns += element.paydownAmount
            ? parseInt(element?.paydownAmount, 10)
            : 0;
        },
      );
    } else {
      paydowns = 0;
    }
    setTotalPayoffs(payoffs);
    setTotalPaydowns(paydowns);
  }, [loan]);

  useEffect(() => {
    if (
      loan?.borrowerSteps?.hardpullVerification?.paydownInfo &&
      loan?.coborrowerSteps?.hardpullVerification?.paydownInfo
    ) {
      setPaydowns([
        ...(loan?.borrowerSteps?.hardpullVerification?.paydownInfo ?? []),
        ...(loan?.coborrowerSteps?.hardpullVerification?.paydownInfo ?? []),
      ]);
    } else if (loan?.borrowerSteps?.hardpullVerification?.paydownInfo) {
      setPaydowns(loan?.borrowerSteps?.hardpullVerification?.paydownInfo);
    } else if (loan?.coborrowerSteps?.hardpullVerification?.paydownInfo) {
      setPaydowns(loan?.coborrowerSteps?.hardpullVerification?.paydownInfo);
    } else {
      setPaydowns([]);
    }
  }, [loan]);

  const netFund = (newValuesSlider?.initialDrawAmount ?? 0) - (totalFees ?? 0);
  const estNetProceeds =
    netFund - totalPaydowns - totalPayoffs < 0
      ? 0
      : netFund - totalPaydowns - totalPayoffs;
  const parsedEsNeetsAmount =
    estNetProceeds >= 0
      ? dollarFormatter.format(estNetProceeds)
      : dollarFormatter.format(0);

  return (
    <Box
      display="grid"
      alignItems="center"
      sx={{
        backgroundColor: isHomeBridge
          ? "#D3E1EA"
          : theme.palette.background.paper,
        p: 2,
      }}
      gridTemplateColumns="1fr 1fr"
    >
      <Typography sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }} variant="h6">
        Est. Net Proceeds
      </Typography>
      <Box
        sx={{
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
        }}
        display="flex"
        alignItems="center"
      >
        {netFund && estNetProceeds >= 0 ? (
          <>
            <Typography
              color={theme.palette.text.primary}
              fontFamily={"Pro Display Bold"}
              sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
            >
              {parsedEsNeetsAmount}
            </Typography>
            <CButton
              sx={{
                minHeight: 0,
                minWidth: 0,
                padding: 0,
                ml: { xs: 0, md: 1 },
                fontFamily: "Pro Display Regular",
                fontSize: { xs: "0.9rem", md: "1.2rem" },
                textDecoration: isHomeBridge ? "underline" : "none",
              }}
              onClick={() => setShowDetails(true)}
            >
              Show details
            </CButton>
          </>
        ) : (
          <Skeleton width="75%" height={"1.5rem"} />
        )}
      </Box>
      {showDetails && (
        <ShowNetDetailsModal
          openModal={showDetails}
          onClose={() => {
            setShowDetails(false);
          }}
          paydowns={paydowns}
          payoffs={loan?.payoff}
          loan={loan}
        />
      )}
    </Box>
  );
};

export default EstNetProceeds;
