// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "screens/Home";
import Login from "screens/Login";
import ErrorView from "screens/404";
import Layout from "layouts/Layout";
import Lenders from "screens/Lenders";
import Contactus from "screens/ContactUs";
import FinalOffer from "screens/FinalOffer";
import HomeMonitor from "screens/HomeMonitor";
import ForgotPassword from "screens/ForgotPassword";
import BorrowerTracker from "screens/BorrowerTracker";
import ValidateAccount from "screens/ValidateAccount";
import LayoutWithContexts from "layouts/LayoutWithContexts";
import TypeOfCreditLine from "screens/TypeOfCreditLine";
import CoborrowerTracker from "screens/CoborrowerTracker";
import ResendValidateCode from "screens/ResendValidateCode";
import BorrowerRegisterForm from "screens/BorrowerRegisterForm";
import CoborrowerRegisterForm from "screens/CoborrowerRegisterForm";
import ConfirmNewPassword from "screens/ConfirmNewPassword";
import BorrowerInviteRegisterForm from "screens/BorrowerInviteRegisterForm";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import Licenses from "screens/Licenses";
import { LStoken } from "CONST";
import useUserCustomerContext from "context/UserCustomer/useUserCustomerContext";
import CLoader from "components/CLoader";
import ScreenAfterMLORegister from "screens/ScreenAfterMLORegister";
import { AdminImpersonate } from "screens/AdminImpersonate";
import Loan from "typedef/Loan";
import LoansScreen from "screens/Loans";
import { FlagsProvider } from "flagged";
import { HBHome } from "screens/HBHome";
import { HBFaqAndResources } from "screens/HBFaqAndResources";
import { HBResourcesDetails } from "screens/HBResourcesDetails";
import { GetStarted } from "screens/GetStarted";
import { useIsHomebridge } from "utils/useIsHomebridge";
import { useMetaTags } from "utils/useMetaTags";
import { HBFastEasyPay } from "components/HBFastEasyPay";
import { HBHomeOwners } from "components/HBHomeOwners";
export type UserType = {
  id?: string;
  email?: string;
  token?: string;
  lastName?: string;
  firstName?: string;
};

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredCondition: (
    loan: Loan | undefined,
    user: UserType | undefined,
  ) => boolean;
  redirectPath?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  requiredCondition,
  redirectPath = "/login",
}) => {
  const { loadingUserData, loadingLoan, user, loan } = useUserCustomerContext();

  const navigate = useNavigate();
  const [userLoaded, setUserLoaded] = useState(false);
  const token = localStorage.getItem(LStoken);
  useEffect(() => {
    if (!loadingUserData && user !== undefined) {
      setUserLoaded(true);
    }
  }, [loadingUserData, user]);

  useEffect(() => {
    if (!token) {
      const hasToken = localStorage.getItem(LStoken);
      if (!hasToken || !user || !requiredCondition(loan, user)) {
        navigate(redirectPath, { replace: true });
      }
    }
  }, [
    userLoaded,
    loadingLoan,
    user,
    loan,
    requiredCondition,
    navigate,
    redirectPath,
    token,
  ]);

  if (loadingUserData || loadingLoan || !userLoaded) {
    return <CLoader loading={true} />;
  }

  return <>{children}</>;
};

const AppRoutes = () => {
  const { privateLabel } = usePrivateLabel();
  const { pathname, search } = useLocation();
  const { isHomeBridge, metaTags } = useIsHomebridge();
  const { updateMetaTags } = useMetaTags();
  const hasToken = localStorage.getItem(LStoken);
  const queryParams = new URLSearchParams(window.location.search);
  const hasInviteCode = queryParams.has("code");
  const isMyLoansBorrowerTrackerOrFinalOffer =
    pathname.includes("/loans") ||
    pathname.includes("/impersonate") ||
    pathname.includes("/borrower-tracker") ||
    pathname.includes("/coborrower-tracker") ||
    pathname.includes("/final-offer");
  const [featureFlags, setFeatureFlags] = useState({});

  const currentTag = metaTags[pathname] || metaTags["/"];

  //extract feature flags from privateLabel object and set
  useEffect(() => {
    setFeatureFlags(privateLabel?.featureFlags || {});
  }, [privateLabel?.featureFlags]);

  if (isHomeBridge && isMyLoansBorrowerTrackerOrFinalOffer) {
    const root = document.documentElement;

    root.style.setProperty("--palette-background-default", "#FFFFFF");
  }

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty(
      "--palette-primary-main",
      `${privateLabel?.pallete?.primary?.main}`,
    );
    root.style.setProperty(
      "--palette-text-primary",
      `${privateLabel?.pallete?.text?.secondary}`,
    );
    root.style.setProperty(
      "--palette-background-paper",
      `${privateLabel?.pallete?.background?.paper}`,
    );
    root.style.setProperty(
      "--palette-text-secondary",
      `${privateLabel?.pallete?.text?.secondary}`,
    );
    root.style.setProperty(
      "--palette-background-default",
      `${
        isHomeBridge
          ? isMyLoansBorrowerTrackerOrFinalOffer
            ? "#FFFFFF"
            : "#102939"
          : privateLabel?.pallete?.background?.default
      }`,
    );
    root.style.setProperty(
      "--palette-headings-main",
      `${privateLabel?.pallete?.headings?.main}`,
    );
    root.style.setProperty(
      "--palette-headings-secondary",
      `${privateLabel?.pallete?.headings?.secondary}`,
    );
    root.style.setProperty(
      "--palette-body-main",
      `${privateLabel?.pallete?.body?.main}`,
    );
    root.style.setProperty(
      "--palette-body-secondary",
      `${privateLabel?.pallete?.body?.secondary}`,
    );
  }, [
    privateLabel?.pallete,
    isHomeBridge,
    isMyLoansBorrowerTrackerOrFinalOffer,
  ]);

  const isBorrower = (
    loan: Loan | undefined,
    user: UserType | undefined,
  ): boolean => Boolean(loan && user && user.id === loan.borrowerId);

  const isCoborrower = (
    loan: Loan | undefined,
    user: UserType | undefined,
  ): boolean => Boolean(loan && user && user.id === loan.coborrowerId);

  const typeOfCreditLineCondition = (
    loan: Loan | undefined,
    user: UserType | undefined,
  ): boolean =>
    Boolean(
      loan &&
        isBorrower(loan, user) &&
        (!loan.borrowerFlags?.userHasLoanInOtherPrivateLabel ||
          loan.borrowerFlags?.completedByBorrower),
    );

  const finalOfferCondition = (
    loan: Loan | undefined,
    user: UserType | undefined,
  ): boolean => {
    const debtsValidationB1 = loan?.borrowerFlags?.debtsCofirmed;

    const debtsValidationB2 =
      loan &&
      (loan?.coborrowerId ? loan?.coborrowerFlags?.debtsCofirmed : true);

    const confirmedDebts =
      (debtsValidationB1 && debtsValidationB2) ||
      loan?.initialOffer?.useProceeds !== "Debt Consolidation";

    return Boolean(
      loan &&
        isBorrower(loan, user) &&
        ((!loan?.borrowerFlags?.finalOfferAccepted &&
          loan?.borrowerSteps?.incomeVerification?.status === "success" &&
          loan?.borrowerSteps?.creditVerification?.status === "success" &&
          loan?.borrowerSteps?.titleVerification?.status === "success" &&
          loan?.borrowerSteps?.idVerification?.status === "success" &&
          (loan.borrowerSteps.homeValuationVerification.status === "success" ||
            (loan.borrowerSteps.homeValuationVerification.status ===
              "analyzing" &&
              loan.borrowerSteps?.homeValuationVerification?.reportType ===
                "BPO" &&
              loan.borrowerSteps?.homeValuationVerification?.orderId))) ||
          loan?.borrowerFlags?.reAcceptedFinalOffer === false) &&
        confirmedDebts,
    );
  };

  const isFunded = (loan: Loan | undefined): boolean =>
    Boolean(
      loan && loan.borrowerSteps?.fundingVerification?.status === "success",
    );

  const borrowerTrackerCondition = (
    loan: Loan | undefined,
    user: UserType | undefined,
  ): boolean => {
    const queryParams = new URLSearchParams(search);
    const isStatusQuery =
      pathname.endsWith("/borrower-tracker") && queryParams.has("status");

    return Boolean(
      isStatusQuery ||
        (loan &&
          isBorrower(loan, user) &&
          loan.borrowerSteps !== undefined &&
          !isFunded(loan)),
    );
  };

  const coborrowerTrackerCondition = (
    loan: Loan | undefined,
    user: UserType | undefined,
  ): boolean =>
    Boolean(
      loan && isCoborrower(loan, user) && loan.coborrowerSteps !== undefined,
    );

  if (isHomeBridge) {
    updateMetaTags(currentTag.title);
  }
  return (
    <FlagsProvider features={{ featureFlags }}>
      <Routes>
        <Route element={<LayoutWithContexts />}>
          {privateLabel?.isNFTYDoor && (
            <Route path="/lenders" element={<Lenders />} />
          )}
          <Route
            path="/final-offer"
            element={
              <ProtectedRoute requiredCondition={finalOfferCondition}>
                <FinalOffer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/home-monitor"
            element={
              <ProtectedRoute requiredCondition={(loan) => isFunded(loan)}>
                <HomeMonitor isAdmin={false} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/type-of-credit-line"
            element={
              <ProtectedRoute requiredCondition={typeOfCreditLineCondition}>
                <TypeOfCreditLine />
              </ProtectedRoute>
            }
          />
          <Route
            path="/coborrower-tracker"
            element={
              <ProtectedRoute requiredCondition={coborrowerTrackerCondition}>
                <CoborrowerTracker />
              </ProtectedRoute>
            }
          />
          <Route
            path="/borrower-tracker"
            element={
              <ProtectedRoute
                requiredCondition={(loan, user) =>
                  borrowerTrackerCondition(loan, user) ||
                  coborrowerTrackerCondition(loan, user)
                }
              >
                <BorrowerTracker />
              </ProtectedRoute>
            }
          />
          <Route
            path="/loans"
            element={
              <ProtectedRoute requiredCondition={() => true}>
                <LoansScreen isAdmin={false} />
              </ProtectedRoute>
            }
          />
          {(privateLabel?.hasOrganicFlow || hasInviteCode) && (
            <>
              <Route path="/signup" element={<BorrowerRegisterForm />} />
              <Route
                path="/:sourceId/signup"
                element={<BorrowerRegisterForm />}
              />
              <Route
                path="/coborrower-signup"
                element={<CoborrowerRegisterForm />}
              />
            </>
          )}
          <Route
            path="/:wholesaleEntity/:brokerName/:loName/signup"
            element={<BorrowerInviteRegisterForm />}
          />
          <Route path={`/submitted`} element={<ScreenAfterMLORegister />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/" element={isHomeBridge ? <HBHome /> : <Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/confirm-new-password"
            element={<ConfirmNewPassword />}
          />
          <Route
            path="/resend-validate-code"
            element={<ResendValidateCode />}
          />
          <Route
            path="/validate-account/:email"
            element={<ValidateAccount />}
          />
          <Route path="/licenses" element={<Licenses />} />
          <Route path="/contactus" element={<Contactus />} />
          {!hasToken && (
            <>
              <Route
                path="/borrower-tracker"
                element={<Navigate to="/login" replace />}
              />
              <Route
                path="/coborrower-tracker"
                element={<Navigate to="/login" replace />}
              />
              <Route
                path="/final-offer"
                element={<Navigate to="/login" replace />}
              />
              <Route
                path="/home-monitor"
                element={<Navigate to="/login" replace />}
              />
              <Route
                path="/initial-offer"
                element={<Navigate to="/login" replace />}
              />
              <Route
                path="/type-of-credit-line"
                element={<Navigate to="/login" replace />}
              />
              <Route path="/loans" element={<Navigate to="/login" replace />} />
            </>
          )}
          <Route
            path="impersonate/:borrowerType/:loanId"
            element={<AdminImpersonate />}
          />
          {isHomeBridge && (
            <>
              <Route path="/get-started" element={<GetStarted />} />
              <Route path="/faq" element={<HBFaqAndResources />} />
              <Route path="/resources" element={<HBFaqAndResources />} />
              <Route path="/resources/:id" element={<HBResourcesDetails />} />
            </>
          )}
          <Route path="*" element={<ErrorView />} />
        </Route>
        {isHomeBridge && (
          <>
            <Route path="/home-owners" element={<HBHomeOwners />} />
            <Route path="/easy-pay" element={<HBFastEasyPay />} />
          </>
        )}
      </Routes>
    </FlagsProvider>
  );
};

export default AppRoutes;
