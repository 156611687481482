import {
  Box,
  useTheme,
  Container,
  useMediaQuery,
  Typography,
  Button,
} from "@mui/material";
import useUserCustomerContext from "context/UserCustomer/useUserCustomerContext";
import { useNavigate } from "react-router-dom";
import "./my-loans.scss";
import UserTable from "./UserTable";
import color from "context/MaterialUITheme/color";
import { useState } from "react";
import API from "utils/API";
import { useIsHomebridge } from "utils/useIsHomebridge";

const LoansScreen: React.FC<{
  isAdmin?: boolean;
}> = ({ isAdmin }) => {
  const theme = useTheme();
  const { isHomeBridge } = useIsHomebridge();
  const navigate = useNavigate();
  const { setSelectedLoanId } = useUserCustomerContext();
  const [loanOfficerIdLastLoan, setLoanOfficerIdLastLoan] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleRedirectStartLoan = async () => {
    setIsLoading(true);
    setSelectedLoanId("");
    if (loanOfficerIdLastLoan) {
      const response = await API.get<string>(
        `/get/permanent-link?id=${loanOfficerIdLastLoan}`,
      );
      if ("error" in response) {
        console.error(response.error);
      } else {
        const inivteLink = response?.data;
        navigate(inivteLink);
      }
    } else {
      navigate("/signup");
    }
    setIsLoading(false);
  };
  return (
    <Container
      className="loans__container"
      maxWidth="md"
      disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
      sx={{ marginBottom: 6 }}
    >
      <Box
        className="loans__title"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography
          variant="h4"
          color={
            isHomeBridge
              ? `${theme.palette.text.secondary} !important`
              : color.black
          }
        >
          Your <span>Loans</span>
        </Typography>
        {!isHomeBridge && (
          <Button
            onClick={handleRedirectStartLoan}
            disabled={isAdmin || isLoading}
            sx={{
              background: theme.palette.primary.main,
              display: "flex",
              fontSize: { xs: "0.9rem", md: "1rem" },
              alignItems: "center",
              borderRadius: "12px",
              justifyContent: "center",
              padding: { xs: "0.5rem 0.5rem", md: "0.7rem 1rem" },
              textTransform: "inherit",
              fontFamily: "Basis Grotesque Arabic Pro",
              gap: "3px",
              height: "fit-content",
            }}
            variant="contained"
            data-cy="startNewLoan"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              color={color.white}
              fill="none"
            >
              <path
                d="M12 8V16M16 12L8 12"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
                stroke="currentColor"
                stroke-width="1.5"
              />
            </svg>
            Start a new loan
          </Button>
        )}
      </Box>
      <Box
        className="loans__subtitle"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography
          variant="h5"
          color={
            isHomeBridge
              ? `${theme.palette.text.secondary} !important`
              : color.black
          }
        >
          Here you can see the status of your loans.
        </Typography>
      </Box>

      <UserTable
        isAdmin={isAdmin}
        setLoanOfficerIdLastLoan={setLoanOfficerIdLastLoan}
      />

      {!isHomeBridge && <div className="hero-background"></div>}
    </Container>
  );
};
export default LoansScreen;
