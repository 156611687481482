import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { LoansListProps } from "./UserTable";
import toPercentage from "utils/toPercentage";
import dollarFormatter from "utils/FormatterDollar";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import getRedirectMessage from "./getRedirectMessage";
import { UserType } from "typedef/ApiUserResponses";
import { useIsHomebridge } from "utils/useIsHomebridge";

const PERCENTAGE = 100;
const AMOUNT_OF_DECIMALS = 2;

interface SpringModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  loan?: LoansListProps;
  handleNavigate: () => void;
  user?: UserType;
}

const SpringModal: React.FC<SpringModalProps> = ({
  isOpen,
  setIsOpen,
  loan,
  handleNavigate,
  user,
}) => {
  const parsedInterestRate = toPercentage(
    loan?.finalOffer?.interestRate ?? loan?.initialOffer?.interestRate ?? 0,
  );

  const parsedLoanAmount = dollarFormatter.format(
    loan?.finalOffer?.amount ?? loan?.initialOffer?.amount ?? 0,
  );

  const drawPercentageNumber =
    ((loan?.initialOffer?.initialDrawAmount ?? 0) * PERCENTAGE) /
    (loan?.initialOffer?.amount ?? 0);

  const drawPercentage =
    drawPercentageNumber.toString().includes(".") &&
    drawPercentageNumber.toString().split(".")[1].length > AMOUNT_OF_DECIMALS
      ? drawPercentageNumber.toFixed(AMOUNT_OF_DECIMALS)
      : drawPercentageNumber;

  const estFees =
    (loan?.finalOffer?.amount ?? loan?.initialOffer?.amount ?? 0) -
    (loan?.finalOffer?.netFundingWiredToBank ??
      loan?.initialOffer?.netFundingWiredToBank ??
      0);

  const loanData = [
    { label: "Property", value: loan?.address, fullWidth: false },
    {
      label: "Loan Request",
      value: parsedLoanAmount,
      fullWidth: false,
    },
    {
      label: "Interest Rate",
      value: parsedInterestRate,
      fullWidth: false,
    },
    {
      label: "Draw",
      value: `${dollarFormatter.format(
        loan?.finalOffer?.initialDrawAmount ??
          loan?.initialOffer?.initialDrawAmount ??
          0,
      )} ${`(${drawPercentage}% draw)`}`,
      fullWidth: false,
    },
    {
      label: "Est. Points and Fees",
      value: dollarFormatter.format(estFees),
      fullWidth: false,
    },
    {
      label: "Est. Net Proceeds",
      value: dollarFormatter.format(
        loan?.finalOffer?.netFundingWiredToBank ??
          loan?.initialOffer?.netFundingWiredToBank ??
          0,
      ),
      fullWidth: false,
    },
    { label: "Loan ID", value: loan?.id, fullWidth: false },
    { label: "Status", value: loan?.status, fullWidth: true },
  ];
  const { privateLabel } = usePrivateLabel();
  const { isHomeBridge } = useIsHomebridge();

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setIsOpen(false)}
          className="modal-backdrop"
        >
          <motion.div
            initial={{ scale: 0, rotate: "1deg" }}
            animate={{ scale: 1, rotate: "0deg" }}
            exit={{ scale: 0, rotate: "0deg" }}
            onClick={(e) => e.stopPropagation()}
            className="modal-content"
          >
            <svg
              onClick={() => setIsOpen(false)}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              color="#000000"
              fill="none"
            >
              <path
                d="M14.9994 15L9 9M9.00064 15L15 9"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
                stroke="currentColor"
                stroke-width="1.5"
              />
            </svg>
            <div className="policy-grid">
              {loanData.map((item, index) => (
                <div
                  key={index}
                  className={`policy-item ${
                    item.fullWidth ? "full-width" : ""
                  }`}
                >
                  <label>{item.label}</label>
                  <p>{item.value}</p>
                </div>
              ))}
            </div>
            <button
              onClick={handleNavigate}
              className={`${
                privateLabel?.isNFTYDoor && !isHomeBridge
                  ? "update__button"
                  : "update__buttonpl"
              }`}
            >
              {getRedirectMessage({
                loan,
                isBorrower: loan?.borrowerId === user?.id,
              })}
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SpringModal;
