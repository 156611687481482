import CHeader from "components/CHeader";
import CFooter from "components/CFooter";
import { HBFooter } from "components/HBFooter";
import { Outlet, useLocation } from "react-router-dom";
import { useIsHomebridge } from "utils/useIsHomebridge";
import { InviteCustomerContextProvider } from "context/Invite/useInviteContext";
import { Box } from "@mui/material";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";

const LayoutWithContexts = () => {
  const { isHomeBridge } = useIsHomebridge();
  const location = useLocation();
  const pathName = location.pathname;

  const { primaryText } = useHomeBridgeColors();
  return (
    <InviteCustomerContextProvider>
      {isHomeBridge ? (
        <Box
          className={`homebridge-layout ${
            pathName !== "/" ? pathName.replace("/", "homebridge-layout__") : ""
          }`}
          style={{
            display: "grid",
            minHeight: "100dvh",
            gridTemplateRows: "auto 1fr auto",
            fontFamily: "NunitoSans",
            backgroundColor: primaryText,
          }}
        >
          <CHeader />
          <Outlet />
          <HBFooter />
        </Box>
      ) : (
        <>
          <CHeader />
          <Outlet />
          <CFooter />
        </>
      )}
    </InviteCustomerContextProvider>
  );
};

export default LayoutWithContexts;
