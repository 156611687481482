import {
  Box,
  IconButton,
  MenuItem,
  Select,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { DayButton } from "./useStyles";
import moment, { Moment } from "moment";
import { useMemo } from "react";
import isHolliday from "utils/isHolliday";
import color from "context/MaterialUITheme/color";
import { useIsHomebridge } from "utils/useIsHomebridge";

export interface CCalendarProps {
  date?: Date;
  rangeFrom?: Date;
  rangeTo?: Date;
  excludeHollidays?: boolean;
  onChange?: (newDate: Date) => void;
  sx?: SxProps;
}

const CCalendar: React.FC<CCalendarProps> = ({
  date,
  onChange,
  sx,
  excludeHollidays,
  rangeFrom,
  rangeTo,
}) => {
  const theme = useTheme();
  const { isHomeBridge } = useIsHomebridge();
  const firstDayOfMonth = useMemo(() => moment(date).startOf("month"), [date]);
  const lastDayOfMonth = useMemo(() => moment(date).endOf("month"), [date]);
  const days = useMemo(
    () =>
      Array.from({
        length:
          firstDayOfMonth.daysInMonth() +
          firstDayOfMonth.get("day") +
          (6 - lastDayOfMonth.get("day")),
      }).map((_, index) => {
        return moment(firstDayOfMonth)
          .subtract(firstDayOfMonth.get("day"), "days")
          .add(index, "days");
      }),
    [firstDayOfMonth, lastDayOfMonth],
  );
  const weeks = useMemo(() => {
    const unfilledWeeks: Moment[][] = [];
    for (const day of days) {
      if (!unfilledWeeks[day.get("week") - firstDayOfMonth.get("week")]) {
        unfilledWeeks.push([day]);
      } else {
        unfilledWeeks[day.get("week") - firstDayOfMonth.get("week")].push(day);
      }
    }
    return unfilledWeeks;
  }, [days, firstDayOfMonth]);

  return (
    <Box sx={sx}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Select
          value={firstDayOfMonth.get("month")}
          onChange={({ target }) =>
            onChange?.(
              moment()
                .set("month", Number(target.value))
                .set("date", 1)
                .toDate(),
            )
          }
        >
          <MenuItem value={moment().get("month")}>
            {moment().format("MMMM YYYY")}
          </MenuItem>
          <MenuItem value={moment().add(1, "months").get("month")}>
            {moment().add(1, "months").format("MMMM YYYY")}
          </MenuItem>
        </Select>
        <Box>
          <IconButton
            onClick={() => {
              const newDate = moment(date).subtract(1, "months").set("date", 1);
              if (newDate.diff(moment(), "months") >= 0)
                onChange?.(newDate.toDate());
            }}
          >
            <ArrowForwardIosIcon
              htmlColor={theme.palette.primary.main}
              sx={{ transform: "rotate(180deg)" }}
            />
          </IconButton>
          <IconButton
            onClick={() => {
              const newDate = moment(date).add(1, "months").set("date", 1);
              if (newDate.diff(moment(), "months") < 1)
                onChange?.(newDate.toDate());
            }}
          >
            <ArrowForwardIosIcon htmlColor={theme.palette.primary.main} />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          marginTop={3}
          marginBottom={3}
        >
          <Typography
            fontSize={14}
            width={40}
            textAlign="center"
            color={theme.palette.text.secondary}
          >
            SUN
          </Typography>
          <Typography
            fontSize={14}
            width={40}
            textAlign="center"
            color={theme.palette.text.secondary}
          >
            MON
          </Typography>
          <Typography
            fontSize={14}
            width={40}
            textAlign="center"
            color={theme.palette.text.secondary}
          >
            TUE
          </Typography>
          <Typography
            fontSize={14}
            width={40}
            textAlign="center"
            color={theme.palette.text.secondary}
          >
            WED
          </Typography>
          <Typography
            fontSize={14}
            width={40}
            textAlign="center"
            color={theme.palette.text.secondary}
          >
            THU
          </Typography>
          <Typography
            fontSize={14}
            width={40}
            textAlign="center"
            color={theme.palette.text.secondary}
          >
            FRI
          </Typography>
          <Typography
            fontSize={14}
            width={40}
            textAlign="center"
            color={theme.palette.text.secondary}
          >
            SAT
          </Typography>
        </Box>
        {weeks.map((week, weekIndex) => (
          <Box
            key={weekIndex}
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            marginBottom={1}
          >
            {week.map((day, dayIndex) =>
              day.get("month") === firstDayOfMonth.get("month") ? (
                <DayButton
                  key={dayIndex}
                  disabled={
                    !day.isBetween(moment(rangeFrom), moment(rangeTo)) ||
                    (excludeHollidays && isHolliday(day))
                  }
                  onClick={() => onChange?.(day.toDate())}
                  style={
                    moment(date).isSame(day, "day") &&
                    day.isBetween(moment(rangeFrom), moment(rangeTo))
                      ? {
                          backgroundColor: isHomeBridge
                            ? theme.palette.primary.main
                            : color.primary,
                          color: color.white,
                        }
                      : {}
                  }
                  className={`${
                    (!day.isBetween(moment(rangeFrom), moment(rangeTo)) ||
                      (isHolliday(day) && excludeHollidays)) &&
                    "muted"
                  }`}
                >
                  {day.format("D")}
                </DayButton>
              ) : (
                <DayButton
                  key={dayIndex}
                  style={{
                    cursor: "none",
                    visibility: "hidden",
                  }}
                >
                  null
                </DayButton>
              ),
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CCalendar;
