import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import DrawRequestModal from "../DrawRequestModal";
import { PrivateLabelFees } from "typedef/PrivateLabelFees";
import { FC, useEffect, useState } from "react";
import CButton from "components/CButton";
import { HELOCFormProps } from "typedef/HELOCFormProps";
import API from "utils/API";
import WarningIcon from "@mui/icons-material/Warning";
import dollarFormatter from "utils/FormatterDollar";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { useIsHomebridge } from "utils/useIsHomebridge";

const PERCENTAGE = 100;

const Draw: FC<HELOCFormProps> = ({
  newValuesSlider,
  loading,
  loan,
  initialValuesSliderHELOC,
  marks,
  recalculateOffer,
  drawLessThan100,
  setDrawLessThan100,
}) => {
  const theme = useTheme();
  const { isHomeBridge } = useIsHomebridge();
  const [editDraw, setEditDraw] = useState(false);
  const [fees, setFees] = useState<PrivateLabelFees>();
  const { userAdmin } = useUser();
  const loanId = loan?.id;
  const state = loan?.property.address.components.state_abbreviation;
  const APIUrl = userAdmin?.id
    ? `/getServiceUnsecured/origination-fees?state=${state}&loanId=${loanId}`
    : `/get/origination-fees?loanId=${loanId}`;

  useEffect(() => {
    API.get<PrivateLabelFees>(APIUrl).then((response) => {
      if (!("error" in response)) {
        setFees(response.data);
      }
    });
  }, [APIUrl]);
  //@ts-ignore
  let drawPercentaje;

  if (
    newValuesSlider?.initialDrawAmount &&
    loan?.finalOffer?.initialDrawAmount
  ) {
    drawPercentaje =
      (newValuesSlider?.initialDrawAmount / loan?.finalOffer?.amount) *
      PERCENTAGE;
  }

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr"
      alignItems="center"
      sx={{
        backgroundColor: isHomeBridge
          ? "#D3E1EA"
          : theme.palette.background.paper,
        p: 2,
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
        >
          Draw:
        </Typography>
      </Box>
      <Box
        sx={{
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
        }}
        display="flex"
        alignItems="center"
      >
        {loading || !newValuesSlider?.initialDrawAmount ? (
          <Skeleton width={"75%"} height={"1.4rem"} />
        ) : (
          <>
            <Typography
              variant="h5"
              display="flex"
              alignItems="center"
              color={theme.palette.text.primary}
              fontFamily={"Pro Display Bold"}
              sx={{
                color: drawLessThan100
                  ? theme.palette.warning.main
                  : theme.palette.text.primary,
                fontSize: { xs: "1rem", md: "1.3rem" },
              }}
            >
              {drawLessThan100 && (
                <WarningIcon
                  style={{ fontSize: 25, marginRight: 10 }}
                  htmlColor={theme.palette.warning.main}
                />
              )}
              {dollarFormatter.format(newValuesSlider.initialDrawAmount)}
              {drawLessThan100 && ` (${drawPercentaje?.toFixed(0)}% draw)`}
            </Typography>
            <CButton
              sx={{
                minHeight: 0,
                minWidth: 0,
                padding: 0,
                ml: { xs: 0, md: 1 },
                fontFamily: "Pro Display Regular",
                fontSize: { xs: "0.9rem", md: "1.2rem" },
                textDecoration: isHomeBridge ? "underline" : "none",
              }}
              onClick={() => setEditDraw(true)}
            >
              Edit
            </CButton>
          </>
        )}
      </Box>

      {editDraw && (
        <DrawRequestModal
          loan={loan}
          fees={fees}
          showSlider={initialValuesSliderHELOC?.showSlider}
          openModal={editDraw}
          onClose={() => {
            setEditDraw(false);
          }}
          initialValuesSliderHELOC={initialValuesSliderHELOC}
          marks={marks}
          loading={loading}
          recalculateOffer={recalculateOffer}
          setDrawLessThan100={setDrawLessThan100}
          drawLessThan100={drawLessThan100}
          newValuesSlider={newValuesSlider}
        />
      )}
    </Box>
  );
};

export default Draw;
