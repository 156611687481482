import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Theme } from "@mui/system";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import HBHomeImage from "../../../assets/Images/resource-card-homebridge.png";
import HBHomeIncreasing from "../../../assets/Images/HBHomeIncreasing.svg";
import HBMathBalance from "../../../assets/Images/HBMathBalance.svg";
import { useNavigate } from "react-router-dom";
import { CHBButton } from "components/CHBButton";
import { HBsvgGrid } from "components/HBResources/HBImagesComponents/index";

import SVGHomeIncreasing1 from "../../../assets/Images/HBHomeIncreasingValues1.svg";
import SVGHomeIncreasing2 from "../../../assets/Images/HBHomeIncreasingValues2.svg";
import SVGHomeIncreasing3 from "../../../assets/Images/HBHomeIncreasingValues3.svg";
import SVGHomeIncreasing4 from "../../../assets/Images/HBHomeIncreasingValues4.svg";
import SVGHomeIncreasing5 from "../../../assets/Images/HBHomeIncreasingValues5.svg";

import SVGHomeEquity1 from "../../../assets/Images/HBHomeEquityMath1.svg";
import SVGHomeEquity2 from "../../../assets/Images/HBHomeEquityMath2.svg";
import SVGHomeEquity3 from "../../../assets/Images/HBHomeEquityMath3.svg";
import SVGHomeEquity4 from "../../../assets/Images/HBHomeEquityMath4.svg";

export const HomeEquity = () => {
  const theme = useTheme();
  const { sizes } = useHomebridgeSizes();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  const navigate = useNavigate();

  const HBHomeIncreasingImages = [
    SVGHomeIncreasing1,
    SVGHomeIncreasing2,
    SVGHomeIncreasing3,
    SVGHomeIncreasing4,
    SVGHomeIncreasing5,
  ];

  const HBHomeEquityImages = [
    SVGHomeEquity1,
    SVGHomeEquity2,
    SVGHomeEquity3,
    SVGHomeEquity4,
  ];

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.common.white,
        padding: { xs: "20px 0", md: "68px 0" },
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "80%", md: "70%", lg: "45%" },
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          height: "auto",
          gap: "32px",
          margin: "0 auto",
          color: `${theme.palette.background.paper} !important`,
          fontSize: sizes.typography,
        }}
      >
        <Typography variant={"h4"} fontWeight={"bold"} textAlign={"left"}>
          What is home equity
        </Typography>
        <Box
          component="img"
          src={HBHomeImage}
          alt="home"
          sx={{
            width: "100%",
            maxWidth: "100%",
            height: "auto",
            objectFit: "cover",
          }}
        />
        <Typography
          variant={"subtitle1"}
          fontSize={sizes.typography}
          fontStyle="italic"
        >
          Home equity is one of the most important benefits of being a
          homeowner. So, what is it exactly?
        </Typography>

        <Typography variant={"subtitle1"} fontSize={sizes.typography}>
          Simply stated, home equity is the ownership stake you have in your
          home — the difference between your home’s current value and your
          current mortgage balance (or{" "}
          <span style={{ fontStyle: "italic" }}>balances</span> if you have a
          “second mortgage” like a home equity loan).
        </Typography>
        <Typography
          variant={"h6"}
          fontWeight={"bold"}
          sx={{ fontSize: { xs: "22px" } }}
        >
          How do you use home equity?
          <Typography
            variant="subtitle1"
            fontSize={sizes.typography}
            marginTop={1}
            color={`${theme.palette.background.paper} !important`}
          >
            You can access a portion of your home’s equity and turn it into cash
            (keep reading to find out how). And you can use this cash however
            you like:
            <ul style={{ margin: "0" }}>
              <li>Consolidate high-interest debt, like credit cards</li>
              <li>Make home repairs and improvements</li>
              <li>Cover an unexpected expense or emergency</li>
              <li>Pay off student loans and more</li>
            </ul>
            <br />
            Keep in mind, if you use your home equity for home repairs and
            improvements, the interest you pay may be tax deductible. (Check
            with a tax advisor for details.)
          </Typography>
        </Typography>
        <Typography
          variant={"h6"}
          fontWeight={"bold"}
          sx={{ fontSize: { xs: "22px" } }}
          color={`${theme.palette.background.paper} !important`}
        >
          How do you build home equity?
          <Typography
            variant="subtitle1"
            fontSize={sizes.typography}
            marginTop={1}
            color={`${theme.palette.background.paper} !important`}
          >
            Your home equity generally grows two ways:
            <ol
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginTop: "10px",
                marginBottom: "0",
              }}
            >
              <li>
                With every mortgage payment you make. You can accelerate this
                growth by paying more than the minimum amount due each month or
                switching to biweekly payments.
              </li>
              <li>
                As your home’s value increases over time. Home improvements are
                a smart way to increase both your home’s value and home equity.
                <br />
                <br />
                <Typography
                  variant={"subtitle1"}
                  fontWeight={"bold"}
                  sx={{ fontSize: { xs: "20px" } }}
                  color={`${theme.palette.background.paper} !important`}
                >
                  Did you know: Your home equity grows as your home’s value
                  grows.
                  <Typography
                    variant={"subtitle1"}
                    fontSize={sizes.typography}
                    fontStyle={"italic"}
                  >
                    Home values in the U.S. have soared over the past two
                    decades:
                    {!isMobile ? (
                      <HBsvgGrid
                        images={HBHomeIncreasingImages}
                        direction="row"
                      />
                    ) : (
                      <Box
                        component="img"
                        marginTop={4}
                        maxWidth={"100%"}
                        src={HBHomeIncreasing}
                        alt="Home values increasing"
                      />
                    )}
                  </Typography>
                </Typography>
              </li>
            </ol>
            <br />
            Making a larger down payment when you buy your home is another way
            to build home equity faster.
          </Typography>
        </Typography>
        <Typography
          variant={"h6"}
          fontWeight={"bold"}
          sx={{ fontSize: { xs: "22px" } }}
        >
          How do you calculate your home equity?
          <Typography
            variant="subtitle1"
            fontSize={sizes.typography}
            marginTop={1}
            color={`${theme.palette.background.paper} !important`}
          >
            Home equity is calculated by subtracting your mortgage balance
            (along with any second mortgages like a home equity loan or HELOC)
            from your home’s current value.
            <br />
            <br />
            So, if your home’s value is $500,000 and your mortgage balance is
            $300,000, you “own” $200,000 of your home. This is your total home
            equity. With a HELOC, you can access up to 80% of your home equity.
            In this example, your available home equity would be $160,000.
            <br />
            <br />
            <Typography
              variant={"subtitle1"}
              fontWeight={"bold"}
              sx={{ fontSize: { xs: "20px" } }}
              color={`${theme.palette.background.paper} !important`}
            >
              Let’s take a look at the math:
              <br />
              <br />
              {!isMobile ? (
                <HBsvgGrid images={HBHomeEquityImages} direction="column" />
              ) : (
                <Box
                  component="img"
                  minWidth={"90%"}
                  src={HBMathBalance}
                  alt="Math Balance"
                />
              )}
            </Typography>
          </Typography>
        </Typography>
        <Typography
          variant={"h6"}
          fontWeight={"bold"}
          sx={{ fontSize: { xs: "22px" } }}
        >
          How can you access your home equity?
          <Typography
            variant="subtitle1"
            fontSize={sizes.typography}
            marginTop={1}
            color={`${theme.palette.background.paper} !important`}
          >
            There are a few ways to turn your home equity into cash for home
            improvements, debt consolidation, or whatever you need. Here are two
            of the most common:
            <br />
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                marginTop: "15px",
                marginBottom: "0",
              }}
            >
              <li>
                Home Equity Line of Credit (HELOC) — a line of credit that lets
                you access the cash you need without refinancing.
              </li>
              <li>
                Cash-Out Refinance — a more involved process in which you
                refinance your current mortgage for more than you owe and take
                the extra loan amount as cash.
              </li>
            </ul>
            <br />
            No matter which option you choose, keep in mind that accessing your
            home equity can be a smart way to get the funds you need for pretty
            much anything.
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/resources")}
              >
                Learn more
              </span>{" "}
              about a Homebridge HELOC!
            </span>
          </Typography>
        </Typography>
        <Typography
          variant={"h6"}
          color={theme.palette.background.paper}
          fontWeight={"bold"}
          width={"90%"}
          fontSize={sizes.typography}
          paddingTop={"40px"}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { sx: "30px", md: 4 },
            alignItems: "center",
            justifyContent: { xs: "center", md: "space-between" },
            textAlign: "center",
            alignSelf: "center",
          }}
        >
          <span>Check your rate and choose your amount risk-free!</span>
          <CHBButton
            onClick={() => {
              navigate("/get-started");
            }}
          >
            Get started
          </CHBButton>
        </Typography>
      </Box>
    </Box>
  );
};
