import React, { useMemo, Fragment, useCallback, useEffect } from "react";
import LoanInformation from "./LoanInformation";
import Typography from "@mui/material/Typography";
import QuestionsBox from "components/CQuestionChat";
import BrokerDisclosuresVerification from "./Steps/BrokerDisclosures";
import IdVerification from "./Steps/IdVerification";
import OfferInformation from "./Steps/OfferInformation";
import TitleVerification from "./Steps/TitleVerification";
import CreditVerification from "./Steps/CreditVerification";
import IncomeVerification from "./Steps/IncomeVerification";
import NPEAnnouncement from "./StepsDialogs/NPEAnnouncement";
import FundingVerification from "./Steps/FundingVerification";
import HomeValueVerification from "./Steps/HomeValueVerification";
import FinalOfferVerification from "./Steps/FinalOfferVerification";
import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import DemographicDataVerification from "./Steps/DemographicsVerification";
import ScheduleClosingVerification from "./Steps/ScheduleClosingVerification";
import InitialDisclosuresVerification from "./Steps/InitialDisclosuresVerification";
import ClosingDisclosuresVerification from "./Steps/ClosingDisclosuresVerification";
import AnnuncementCoborrowerPending from "./StepsDialogs/AnnuncementCoborrowerPending";
import AnnuncementCreateNewOfferPending from "./StepsDialogs/AnnuncementCreateNewOfferPending";
import InsuranceVerification from "./Steps/verifyInsurance";
import useTracker from "./useTrackerContext";
import { TrackerStatusColor } from "typedef/TrackerStatusColor";
import UploadDocumentB2 from "./StepsDialogs/UploadDocumentsB2";
import useMissingDocs from "utils/useMissingDocs";
import ConsentForHardpull from "./StepsDialogs/ConsentForHardpull";
import JointAccountAnnouncement from "./StepsDialogs/JointAccountAnnouncement/index";
import { Popup, Session } from "@talkjs/react";
import { useNavigate, useLocation } from "react-router-dom";
import Talk from "talkjs";
import { Session as TalkjsSession } from "talkjs/all";
import { useFeatures } from "flagged";
import getEnvVariable from "../../env";
import { getTalkjsToken } from "utils/talkJs";
import { checkFeatureEnabled } from "utils/featureFlags";
import { useIsHomebridge } from "utils/useIsHomebridge";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";

const CTracker: React.FC<{
  isBorrower: boolean;
}> = ({ isBorrower }) => {
  const theme = useTheme();
  const { loan, coborrowerInviteIsOpen } = useTracker();
  const navigate = useNavigate();
  const location = useLocation();
  const { isHomeBridge } = useIsHomebridge();
  const { sizes } = useHomebridgeSizes();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isImpersonate = location.pathname.includes("impersonate");
    if (!queryParams.has("loanId") && !isImpersonate) {
      navigate("/loans");
    }
  }, [location, navigate]);

  const showAddCoborrower = useMemo(() => {
    if (
      (loan?.coborrowerSteps && loan?.coborrowerId) ||
      loan?.property?.dateAddressMatched
    )
      return false;
    else return true;
  }, [loan]);

  const showAddJACoborrower = useMemo(() => {
    if (
      loan?.borrowerSteps?.incomeVerification?.isJointAccount
        ?.isJointAccount === false &&
      !loan?.coborrowerId &&
      loan?.borrowerSteps?.incomeVerification?.incomeSelection === "plaidCRA"
    ) {
      return true;
    } else return false;
  }, [loan]);

  const showDocUploadModal = useMemo(() => {
    if (loan?.coborrowerFlags?.showedDocUploadModal) return false;
    else return true;
  }, [loan]);

  const borrowerWithoutHardpull = useMemo(() => {
    if (
      (loan?.borrowerFlags?.boConsentedHardpull !== undefined &&
        loan?.borrowerFlags?.boConsentedHardpull !== true &&
        loan?.borrowerSteps?.idVerification?.status === "success" &&
        loan?.borrowerSteps?.creditVerification?.status !== "failed") ||
      (!loan?.borrowerSteps?.hardpullVerification?.hardCreditScore &&
        loan?.borrowerSteps?.idVerification?.status === "success" &&
        loan?.borrowerSteps?.creditVerification?.status === "success")
    )
      return true;
    else return false;
  }, [loan]);

  const coborrowerWithoutHardpull = useMemo(() => {
    if (
      (loan?.coborrowerFlags?.boConsentedHardpull !== undefined &&
        loan?.coborrowerFlags?.boConsentedHardpull !== true &&
        loan?.coborrowerSteps?.idVerification?.status === "success" &&
        loan?.coborrowerSteps?.creditVerification?.status !== "failed") ||
      (!loan?.coborrowerSteps?.hardpullVerification?.hardCreditScore &&
        loan?.coborrowerSteps?.idVerification?.status === "success" &&
        loan?.coborrowerSteps?.creditVerification?.status !== "failed")
    )
      return true;
    else return false;
  }, [loan]);

  const docsToUpload = useMissingDocs(loan);

  const trackerStatusColor: TrackerStatusColor = {
    failed: theme.palette.error.main,
    pending: theme.palette.text.disabled,
    success: theme.palette.primary.main,
    analyzing: theme.palette.warning.main,
  };

  const hasLoanOfficer = loan?.loanOfficerId && loan?.brokerId;

  /*** TalkJS callbacks ***/
  const syncUser = useCallback(() => {
    return new Talk.User({
      id: (isBorrower ? loan?.borrowerId : loan?.coborrowerId) || "default",
      name: isBorrower
        ? loan?.borrowerFirstName || "Borrower"
        : loan?.coborrowerFullName || "Co borrower",
      email: isBorrower ? loan?.borrowerEmail : "unknown",
    });
  }, [
    isBorrower,
    loan?.borrowerEmail,
    loan?.borrowerFirstName,
    loan?.borrowerId,
    loan?.coborrowerFullName,
    loan?.coborrowerId,
  ]);

  const syncConversation = useCallback(
    (session: TalkjsSession) => {
      const conversation = session.getOrCreateConversation(
        loan?.id || "default_conversation",
      );
      //if loan has been populated, add users to the conversation
      if (loan?.assignedCX) {
        conversation.setParticipant(session.me);
        conversation.setParticipant(
          new Talk.User({
            id: loan.assignedCX.id,
            name: loan.assignedCX.name,
          }),
        );
      } else {
        conversation.leave(); //talkjs adds an "unknown user" otherwise....
      }
      return conversation;
    },
    [loan?.assignedCX, loan?.id],
  );
  /*** End TalkJS callbacks ***/

  //check chat feature flag
  const { featureFlags } = useFeatures();
  const chatEnabled = checkFeatureEnabled(
    featureFlags as unknown as { [featureName: string]: string[] },
    "borrowerTrackerChat",
    loan?.assignedCX?.id as string,
  );

  return (
    <Container
      maxWidth="md"
      disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
      sx={{ marginBottom: isHomeBridge ? 6 : 0 }}
    >
      {
        <Fragment>
          <Box maxWidth="md">
            <Typography
              mt={4}
              mb={5}
              variant="h1"
              lineHeight={1}
              color={theme.palette.text.primary}
              letterSpacing={-0.8}
              px={{ xs: 2, md: 0 }}
              fontSize={
                isHomeBridge ? sizes.title : { xs: "2.5rem", md: "4.2rem" }
              }
            >
              {isHomeBridge
                ? `${loan?.borrowerFirstName}, track your application here!`
                : "Track Your Loan Application"}
            </Typography>
            <Box
              px={{ xs: 2, md: 6 }}
              py={{ xs: 4, md: 5 }}
              bgcolor={
                isHomeBridge ? "#D3E1EA" : theme.palette.background.paper
              }
              sx={{
                scrollbarWidth: "none",
                borderTopLeftRadius: 33,
                borderTopRightRadius: 33,
                borderBottomRightRadius: isHomeBridge ? 33 : 0,
                borderBottomLeftRadius: isHomeBridge ? 33 : 0,
                marginBottom: isHomeBridge ? 4 : 0,
              }}
            >
              <LoanInformation />
            </Box>
            <Box
              mt={0.5}
              px={{ xs: 0, md: 4 }}
              py={{ xs: 2, md: 3 }}
              bgcolor={theme.palette.background.paper}
              sx={{
                scrollbarWidth: "none",
                borderBottomLeftRadius: 33,
                borderBottomRightRadius: 33,
              }}
            >
              <ul
                style={{
                  width: "95vw",
                  margin: "0 auto",
                  padding: "0",
                  maxWidth: "800px",
                }}
              >
                {!isBorrower &&
                  showDocUploadModal &&
                  Boolean(docsToUpload.length) &&
                  docsToUpload.some(
                    (doc) => doc.docName !== "Truv W2 and paystubs",
                  ) && <UploadDocumentB2 />}
                {((isBorrower && borrowerWithoutHardpull) ||
                  (!isBorrower && coborrowerWithoutHardpull)) && (
                  <ConsentForHardpull isBorrower={isBorrower} />
                )}
                {isBorrower &&
                  showAddJACoborrower &&
                  !coborrowerInviteIsOpen && <JointAccountAnnouncement />}
                {isBorrower && <OfferInformation />}
                {isBorrower && showAddCoborrower && <NPEAnnouncement />}
                <IdVerification trackerStatusColor={trackerStatusColor} />
                <CreditVerification trackerStatusColor={trackerStatusColor} />
                {isBorrower && (
                  <HomeValueVerification
                    trackerStatusColor={trackerStatusColor}
                  />
                )}
                <IncomeVerification trackerStatusColor={trackerStatusColor} />
                {isBorrower && <AnnuncementCoborrowerPending />}
                {isBorrower && <AnnuncementCreateNewOfferPending />}
                {isBorrower && <FinalOfferVerification />}
                <DemographicDataVerification
                  trackerStatusColor={trackerStatusColor}
                />
                {hasLoanOfficer && (
                  <BrokerDisclosuresVerification
                    trackerStatusColor={trackerStatusColor}
                  />
                )}
                <InitialDisclosuresVerification
                  trackerStatusColor={trackerStatusColor}
                />
                {isBorrower && (
                  <InsuranceVerification
                    trackerStatusColor={trackerStatusColor}
                  />
                )}

                {isBorrower && (
                  <TitleVerification trackerStatusColor={trackerStatusColor} />
                )}

                <ScheduleClosingVerification
                  trackerStatusColor={trackerStatusColor}
                />

                <ClosingDisclosuresVerification
                  trackerStatusColor={trackerStatusColor}
                />
                {isBorrower && (
                  <FundingVerification
                    trackerStatusColor={trackerStatusColor}
                  />
                )}
              </ul>
            </Box>
          </Box>
          {chatEnabled && (
            <Session
              appId={getEnvVariable("TALKJS_APP_ID")}
              syncUser={syncUser}
              tokenFetcher={getTalkjsToken}
            >
              <Popup syncConversation={syncConversation} />
            </Session>
          )}
          <QuestionsBox />
        </Fragment>
      }
    </Container>
  );
};

export default CTracker;
