import { ThemeOptions } from "@mui/material";
import { PrivateLabel } from "typedef/PrivateLabel";

export const homebridgeTheme = (params: {
  privateLabel?: PrivateLabel;
  primaryText: string;
  secondaryText: string;
  tertiaryText: string;
  fourthText: string;
  primaryBackground: string;
  secondaryBackground: string;
}): ThemeOptions => {
  const {
    fourthText,
    primaryBackground,
    primaryText,
    secondaryBackground,
    secondaryText,
    tertiaryText,
    privateLabel,
  } = params;

  const fontFamilies = [
    '"NunitoSans"',
    '"Titillium Bold"',
    '"Titillium Black"',
    '"Titillium Regular"',
    '"Pro Display Regular"',
    '"Pro Text Semibold"',
    '"Pro Display Bold"',
    '"Pro Display Semibold"',
    '"Pro Display Medium"',
  ].join(",");

  return {
    palette: {
      ...privateLabel?.pallete,
      primary: {
        main: tertiaryText,
      },
      background: {
        paper: primaryBackground,
        default: secondaryBackground,
      },
      text: {
        primary: tertiaryText,
        secondary: primaryText,
        disabled: fourthText,
      },
    },
    typography: {
      fontFamily: fontFamilies,
      h1: { fontFamily: "Pro Display Bold" },
      h2: { fontFamily: "Pro Display Semibold" },
      h3: { fontFamily: "NunitoSans" },
      h4: { fontFamily: "NunitoSans" },
      h5: { fontFamily: "NunitoSans", color: primaryText },
      h6: { fontFamily: "NunitoSans" },
      subtitle1: { fontFamily: "NunitoSans" },
      subtitle2: { fontFamily: "NunitoSans", color: primaryText },
      body1: { fontFamily: "NunitoSans", color: primaryText },
      body2: { fontFamily: "NunitoSans", textTransform: "none" },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: primaryText,
            borderRadius: "12px",
            background: fourthText,
            fontFamily: "NunitoSans",
            borderColor: fourthText,
            ":hover": {
              borderColor: fourthText,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: fourthText,
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: fourthText,
            },
            "& .Mui-disabled": {
              borderRadius: "12px",
              backgroundColor: fourthText,
              border: `1px solid ${fourthText}`,
              textFillColor: "gray",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            fontWeight: 700,
            borderColor: tertiaryText,
            backgroundColor: tertiaryText,
            borderWidth: "0.01rem !important",
            color: secondaryText,
            ":hover": {
              backgroundColor: primaryText,
            },
            ":disabled": {
              color: "rgba(0, 0, 0, 0.26)",
              backgroundColor: secondaryText,
              borderColor: fourthText,
            },
          },
          outlined: {
            fontWeight: 700,
            color: primaryText,
            borderColor: tertiaryText,
            backgroundColor: secondaryText,
            borderWidth: "0.01rem !important",
            ":hover": {
              backgroundColor: primaryText,
              color: secondaryText,
              borderColor: fourthText,
            },
            ":disabled": {
              color: fourthText,
              backgroundColor: secondaryText,
              borderColor: fourthText,
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            color: primaryText,
            borderRadius: "12px",
            padding: "1rem",
          },
          paper: {
            color: primaryText,
            backgroundColor: secondaryText,
          },
          paperWidthXs: {
            maxWidth: 410,
          },
          paperWidthMd: {
            maxWidth: 500,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              color: "black",
              backgroundColor: "#d3e1ea",
              borderColor: "transparent",
            },
            "& legend": {
              width: "0",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          formControl: {
            position: "relative",
          },
          root: {
            transform: "unset !important",
            padding: "0 0.2rem",
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            gap: "4px",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: "black",
            backgroundColor: fourthText,
          },
          input: {
            "&.Mui-disabled": {
              borderRadius: "12px",
              backgroundColor: fourthText,
              border: `1px solid ${fourthText}`,
              textFillColor: "gray",
            },
          },
        },
      },
    },
  };
};
