import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import {
  HBRaceOptions,
  validateRace,
} from "./DemographicCheckboxesSteps/HBRaceOptions";
import {
  HBSexOptions,
  validateSexAndCitizenship,
} from "./DemographicCheckboxesSteps/HBSexOptions";
import { HBCitizenship } from "./DemographicCheckboxesSteps/HBCitizenship";
import { FormData } from "../../useGetStarted";
import {
  HBEthnicityOptions,
  validateEthnicity,
} from "./DemographicCheckboxesSteps/HBethnicityOptions";

type DemographicStepsProps = {
  formData: FormData;
  setFormData: (data: FormData) => void;
};

export const DemographicSteps = ({
  formData,
  setFormData,
}: DemographicStepsProps) => {
  const MiniStepsArray = useMemo(
    () => [
      {
        component: (
          <HBEthnicityOptions formData={formData} setFormData={setFormData} />
        ),
        label: "Ethnicity",
        validate: validateEthnicity,
      },
      {
        component: (
          <HBRaceOptions formData={formData} setFormData={setFormData} />
        ),
        label: "Race",
        validate: validateRace,
      },
      {
        component: (
          <Box display={"flex"} flexDirection={"column"} gap={3}>
            <HBSexOptions formData={formData} setFormData={setFormData} />
            <HBCitizenship formData={formData} setFormData={setFormData} />
          </Box>
        ),
        label: "Sex and Citizenship",
        validate: validateSexAndCitizenship,
      },
    ],
    [formData, setFormData],
  );

  const [activeMiniStep, setActiveMiniStep] = useState(0);
  const [error, setError] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (activeMiniStep < MiniStepsArray.length) {
      const isValid = MiniStepsArray[activeMiniStep].validate(formData);
      if (isValid) {
        setError(false);
        setActiveMiniStep((prev) =>
          Math.min(prev + 1, MiniStepsArray.length - 1),
        );
      } else {
        setError(true);
      }
    }
  }, [formData, activeMiniStep, MiniStepsArray]);

  return (
    <Box sx={{ maxWidth: "100%", flexGrow: 1, mt: 3 }}>
      {MiniStepsArray.map((step, index) => (
        <Box
          key={step.label}
          sx={{ mb: 4, opacity: index <= activeMiniStep ? 1 : 0.5 }}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              fontWeight: index === activeMiniStep ? "bold" : "normal",
              color: index <= activeMiniStep ? "inherit" : "gray",
            }}
          >
            {step.label}
          </Typography>
          {index <= activeMiniStep && (
            <>
              {step.component}
              {error && activeMiniStep === index && (
                <Typography
                  color={theme.palette.warning.main}
                  sx={{
                    mt: 1,
                    animation: "shake 0.3s ease-in-out",
                    fontFamily: "NunitoSans",
                  }}
                >
                  Please select at least one option to continue
                </Typography>
              )}
            </>
          )}
        </Box>
      ))}
    </Box>
  );
};
