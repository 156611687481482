import { Typography, Box } from "@mui/material";
import { useState } from "react";

import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { PropertyProps } from "../Property";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";

export const CreditScore = ({
  onNext,
  onPrev,
  formData,
  setFormData,
}: PropertyProps) => {
  const { sizes } = useHomebridgeSizes();
  const [errorMessage, setErrorMessage] = useState(false);
  const [animation, setAnimation] = useState(false);

  const findCurrentActive = () => {
    const field = creditScore.find(
      (score) => score.value === formData.creditScore,
    );
    return field;
  };

  const [currentActive, setCurrentActive] = useState<
    | {
        id: number;
        name: string;
        value: number;
      }
    | undefined
  >(findCurrentActive());

  const handleNext = () => {
    if (!formData.creditScore) {
      setAnimation(true);
      setErrorMessage(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      setErrorMessage(false);
      onNext();
    }
  };

  const handleSelect = (value: number) => {
    const field = creditScore[value];
    setCurrentActive(field);
    setFormData({ ...formData, creditScore: field.value });
    setErrorMessage(false);
  };

  return (
    <div>
      <div className="get-started__wrapper get-started__credit-score">
        <Box width={"100%"}>
          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "grid",
              },
              gridTemplateColumns: {
                sm: "repeat(3, 1fr)",
              },
              flexWrap: "wrap",
              gap: "10px",
              justifyContent: "center",
              justifyItems: "center",
              marginTop: "30px",
              marginBottom: "30px",
            }}
          >
            {creditScore.map((score) => (
              <CHBButton
                className={`animated-button animated-button--score ${
                  currentActive?.id && score.id === currentActive?.id
                    ? "animated-button--active"
                    : ""
                } ${errorMessage ? "animated-button--error" : ""}`}
                classNameContainer={`animated-arrow-button-container--score `}
                key={score.id}
                onClick={() => handleSelect(score.id - 1)}
                style={{
                  animation: animation ? "shake 0.3s ease-in-out" : "none",
                }}
              >
                <Typography
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={sizes.typography}
                  fontWeight={"bold"}
                  marginBottom={"10px"}
                  className="custom-typography text-black"
                >
                  {score.name}
                </Typography>
              </CHBButton>
            ))}
          </Box>
          <p
            className="get-started__note"
            style={{
              textAlign: "center",
            }}
          >
            <strong className="strong-700">Not sure?</strong> You may be able to
            find this by logging in to your account on your bank's site.
          </p>

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column-reverse",
                sm: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              marginTop: "40px",
            }}
          >
            <CHBButton onClick={onPrev} secondaryArrow={true}>
              Go Back
            </CHBButton>
            <CHBButton onClick={handleNext} className="animated-arrow-button">
              Next
            </CHBButton>
          </Box>
        </Box>
      </div>
      <HBSeparate className="step-separate" type="vertical" />
    </div>
  );
};

const creditScore = [
  {
    id: 1,
    name: "640-659",
    value: 640,
  },
  {
    id: 2,
    name: "660-679",
    value: 660,
  },
  {
    id: 3,
    name: "680-699",
    value: 680,
  },
  {
    id: 4,
    name: "700-719",
    value: 700,
  },
  {
    id: 5,
    name: "720-739",
    value: 720,
  },
  {
    id: 6,
    name: "740-759",
    value: 740,
  },
  {
    id: 7,
    name: "760-779",
    value: 760,
  },
  {
    id: 8,
    name: "780-850",
    value: 780,
  },
  {
    id: 9,
    name: "I don't know",
    value: 780,
  },
];
