import { useState } from "react";
import { FormData } from "../../useGetStarted";
export type Errors = {
  field: keyof FormData;
  message: string;
  success?: boolean;
  regex?: RegExp;
};

export const useBorrowerPersonalData = () => {
  const [errors, setErrors] = useState<Errors[]>([]);
  const validateInputs = (
    inputs:
      | { field: keyof FormData; value: string }[]
      | { field: keyof FormData; value: string },
  ) => {
    const checks: Errors[] = [
      {
        regex: /^[a-zA-Z]+$/,
        message: "Must contain only letters.",
        success: false,
        field: "firstName",
      },
      {
        regex: /^[a-zA-Z]+$/,
        message: "Must contain only letters.",
        success: false,
        field: "lastName",
      },
      {
        regex: /^$|^[a-zA-Z]+$/,
        message: "Must contain only letters.",
        success: false,
        field: "middleName",
      },
      {
        regex: /^$|^[a-zA-Z]+$/,
        message: "Must contain only letters.",
        success: false,
        field: "suffix",
      },
      {
        regex: /^(Unmarried|Married|Separated)$/,
        message: "Invalid marital status.",
        success: false,
        field: "maritalStatus",
      },
      {
        regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Email address must be valid.",
        success: false,
        field: "email",
      },
      {
        regex: /^[+\d]{1}[\d\s]{11,15}$/,
        message: "Invalid mobile phone number format.",
        success: false,
        field: "phoneNumber",
      },
      {
        regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{9,}$/,
        message:
          "Password must be at least 9 characters and include both upper and lower case characters, at least 1 symbol character, and at least 1 number.",
        success: false,
        field: "password",
      },
    ];

    const validationErrors: Errors[] = [];

    const isArray = Array.isArray(inputs);

    if (isArray) {
      inputs.forEach(({ field, value }) => {
        const currentChecks = checks.filter((check) => check.field === field);

        currentChecks.forEach((currentCheck) => {
          const trimmedValue = field === "phoneNumber" ? value.trim() : value;

          if (currentCheck && !currentCheck.regex?.test(trimmedValue)) {
            validationErrors.push({
              field,
              message: currentCheck.message,
              success: false,
            });
          }
        });
      });
    } else {
      const currentErrors = [...errors];

      const filteredCurrentErrors = currentErrors.filter(
        (error) => error.field !== inputs.field,
      );

      const currentChecks = checks.filter(
        (check) => check.field === inputs.field,
      );
      currentChecks.forEach((currentCheck) => {
        const trimmedValue =
          currentCheck.field === "phoneNumber"
            ? inputs.value.trim()
            : inputs.value;
        if (!currentCheck.regex?.test(trimmedValue)) {
          filteredCurrentErrors.push({
            field: inputs.field,
            message: currentCheck.message,
            success: false,
          });
        }
      });
      validationErrors.push(...filteredCurrentErrors);
    }

    setErrors(validationErrors);
    return validationErrors;
  };
  return { validateInputs, errors };
};
